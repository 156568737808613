import { TextFieldMasked } from '@web/atomic/legacy/atm.text-field/text-field-masked.component';
import * as React from 'react';

export interface DurationFieldProps {
  placeholder?: string;
  disabled?: boolean;
}

export const DurationField: React.FunctionComponent<DurationFieldProps> = (props) => {
  return (
    <TextFieldMasked
      disabled={props.disabled}
      kind={'datetime'}
      options={{ format: 'HH:mm' }}
      placeholder={props.placeholder}
      type={'tel'}
      pattern="[0-9]{3}:[0-9]{2}"
      maxLength={8}
    />
  );
};
