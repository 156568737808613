/* eslint-disable no-extra-boolean-cast */
import { parseHour } from '@global/utils/date/parse-hour';
import { performHourSum } from '@global/utils/date/perform-hour-sum';
import { toHour } from '@global/utils/date/to-hour';
import { Mutation_Root, Sleep_Restriction } from '@global/utils/remote-graphql-types';
import { TimeField } from '@web/atomic/atm.time-field/time-field.component';
import { DurationField } from '@web/atomic/atm.duration-field/duration-field.component';
import { SleepRestrictionConfigMutation } from '@lp-root/src/data/graphql/hasura/sleep-restriction-config.mutation.hasura';
import { SleepRestrictionConfigQuery } from '@lp-root/src/data/graphql/hasura/sleep-restriction-config.query.hasura';
import { Body, Col, FaIcon, Grid, H1, Hbox, InputLabel, Row, Separator, ThemeContainer } from '@web/atomic';
import { Placeholder } from '@web/atomic/legacy/mol.placeholder';
import { TextShimmerBoxStyled } from '@web/atomic/legacy/mol.shimmer/shimmer.component.style';
import { ShimmerInput } from '@web/atomic/legacy/mol.shimmer/shimmer.options';
import { ForcedFade } from '@web/atomic/legacy/obj.animation/animation.component.style';
import flashDispatcherService from '@web/atomic/legacy/obj.flash-wrapper/flash-dispatcher.service';
import { Form, FormData, Validators } from '@web/atomic/legacy/obj.form';
import { GatsbyButton } from '@components/atm.button/button.component';
import { isTimeValid } from '@web/atomic/legacy/obj.form/regex-validator';
import { useMutationCustom } from '@web/data/use-mutation-custom.hook';
import { useQueryCustom } from '@web/data/use-query-custom.hook';
import { isMobile } from '@web/utils/browser-detection';
import { logError } from '@web/utils/error';
import { getCanonicalUrl } from '@web/utils/url';
import { graphql } from 'gatsby';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { SEO } from '../../components/legacy/mol.seo/seo.component';
import WebviewLayout from '../../components/org.layout/webview-layout.component';
import { PageProps } from '../../utils/local-types';
import { toMinutes } from '@global/utils/date';
import { useCloseMessengerModalCallback } from '@web/atomic/obj.custom-hooks/close-messenger-modal.hook';
import { usePostUsingMessenger } from '@web/data/use-post-using-messenger.hook';
import { PostUrl } from '@web/data/vigilantes.datasource';
import { buildChatfuelBroadcastRequest } from '@lp-root/src/data/request-utils/redirect.request';
import { UserIdDataSource } from '@web/data/user-id.datasource';
import { useQueryParams } from '@web/atomic/obj.custom-hooks/query-params';
import { ChatfuelBlockNames } from '@global/utils/chat/chatfuel-blocks-types';
import { ChatfuelBroadcastRequest } from '@global/utils/chat/chatfuel';
import { HasuraTime } from '@global/utils/remote-graphql-types.extra';

export interface ConfigurationPageFormData {
  hourToSleep: HasuraTime;
  desiredWakeUp: HasuraTime;
  bedTime: HasuraTime;
}

interface SleepRestrictionConfigParams {
  redirect?: ChatfuelBlockNames;
}

const SleepRestrictionConfig: React.FunctionComponent<PageProps> = (props) => {
  const [sleepRestriction, userLoading, userError] = useGetSleepRestriction();
  const [submitSleepRestrictionConfig, { loading: submitLoading }] = useMutateSleepRestriction();
  const { close, loading: waitCloseLoading } = useCloseMessengerModalCallback();
  const [post] = usePostUsingMessenger<ChatfuelBroadcastRequest>({ onSuccess: close, url: PostUrl.RedirectUser });

  const queryParams = useQueryParams<SleepRestrictionConfigParams>();
  const redirectBlock: ChatfuelBlockNames = queryParams.redirect;
  const handleSubmit = async (formData: FormData<ConfigurationPageFormData>) => {
    const { hourToSleep, desiredWakeUp, bedTime } = formData.data;
    const submitResponse = submitSleepRestrictionConfig({
      variables: {
        startDate: sleepRestriction.start_date,
        id: sleepRestriction.user_id,
        object: {
          hour_to_sleep: hourToSleep,
          desired_wake_up: desiredWakeUp,
          comment: `${sleepRestriction.comment || ''};${sleepRestriction.hour_to_sleep}-${sleepRestriction.desired_wake_up}`,
          new_time_in_bed: toMinutes(bedTime) ?? sleepRestriction.new_time_in_bed,
        },
      },
    });

    if (!redirectBlock) {
      return submitResponse;
    }

    console.log('DIARY_DEBUG: configuracao-restricao-do-sono.tsx ~ line 76 ~ handleSubmit ~ userId');
    const userId = await UserIdDataSource.getId();
    const request = buildChatfuelBroadcastRequest(userId, redirectBlock, {
      sleep_restriction_new_time_in_bed: bedTime,
      sleep_restriction_hour_to_sleep: hourToSleep,
      sleep_restriction_desired_wake_up: desiredWakeUp,
    });
    return post(request);
  };

  if (userError) {
    return (
      <WebviewLayout>
        <Grid>
          <Row mt mb>
            <Col xs={12} sm={12} md={7} lg={7}>
              <Placeholder
                icon={<FaIcon.FlashAlert size="9x" />}
                title={'Erro ao pegar seus dados'}
                description="Tente novamente mais tarde"
              />
            </Col>
          </Row>
        </Grid>
      </WebviewLayout>
    );
  }

  return (
    <WebviewLayout>
      <SEO
        socialMedia={{
          canonicalUrl: getCanonicalUrl(props.data.site.siteMetadata.siteUrl, props.location.pathname),
          title: 'Trocar horários da restrição do sono',
        }}
      />
      <Grid>
        <H1>Trocar horários da restrição de sono</H1>
        <Separator />
        {userLoading ? (
          <>
            <TextShimmerBoxStyled width={'60px'} height={'17px'} />
            <Row mt mb>
              <Col xs={12} sm={12} md={7} lg={7}>
                <ShimmerInput />
                <Separator />
              </Col>
            </Row>
          </>
        ) : (
          <ConfigFormRow
            initialSleepRestrictionConfig={sleepRestriction}
            onSubmit={handleSubmit}
            submitLoading={submitLoading || waitCloseLoading}
          />
        )}
      </Grid>
    </WebviewLayout>
  );
};

// ------------------------------------------------------------------------------------------------------
interface ConfigFormProps {
  initialSleepRestrictionConfig: Sleep_Restriction;
  onSubmit: (formData: FormData<ConfigurationPageFormData>) => void;
  submitLoading: boolean;
}

const ConfigFormRow: React.FunctionComponent<ConfigFormProps> = (props) => {
  const { new_time_in_bed, before_eficiency, hour_to_sleep, desired_wake_up } = props.initialSleepRestrictionConfig;

  const [mobile, setMobile] = useState(true);
  useEffect(() => {
    setMobile(isMobile());
  }, []);

  const [value, dispatch] = useReducer(
    (state, action: { type: 'WAKE_UP_CHANGE' | 'GO_SLEEP_CHANGE' | 'BED_TIME_CHANGE'; data: string }) => {
      const { type, data } = action;
      const isDataValid = isTimeValid(data);
      switch (type) {
        case 'BED_TIME_CHANGE':
          return {
            ...state,
            timeInBed: isDataValid ? data : state.timeInBed,
            hourToSleepKey: isDataValid ? performHourSum(-toMinutes(data), state.wakeUp) : state.hourToSleepKey,
            hourToSleep: isDataValid ? performHourSum(-toMinutes(data), state.wakeUp) : state.hourToSleep,
          };
        case 'WAKE_UP_CHANGE':
          return {
            ...state,
            wakeUp: data,
            hourToSleepKey: isDataValid ? data : state.hourToSleepKey,
            hourToSleep: isDataValid ? performHourSum(-toMinutes(state.timeInBed), data) : state.hourToSleep,
          };
        case 'GO_SLEEP_CHANGE':
          return {
            ...state,
            hourToSleep: data,
            wakeUpKey: isDataValid ? data : state.wakeUpKey,
            wakeUp: isDataValid ? performHourSum(toMinutes(state.timeInBed), data) : state.wakeUp,
          };
        default:
          return state;
      }
    },
    {
      hourToSleep: parseHour(hour_to_sleep),
      hourToSleepKey: Date.now(),
      wakeUp: parseHour(desired_wake_up),
      wakeUpKey: Date.now(),
      timeInBed: toHour(new_time_in_bed),
    }
  );
  const handlehourToSleep = (input) => dispatch({ type: 'GO_SLEEP_CHANGE', data: input });
  const handleWakeUp = (input) => dispatch({ type: 'WAKE_UP_CHANGE', data: input });
  const handleBedTime = (input) => dispatch({ type: 'BED_TIME_CHANGE', data: input });

  const bedTimeConfigEnabled = canEditBedTime(new_time_in_bed, before_eficiency);

  const onSubmit = props.onSubmit;
  const handleSubmit = useCallback(
    (formData: FormData<ConfigurationPageFormData>) => {
      if (Object.keys(formData.error).length !== 0) return;
      onSubmit(formData);
    },
    [onSubmit]
  );

  return (
    <>
      <Row mt mb>
        <Col xs={12} sm={12} md={7} lg={7}>
          {!bedTimeConfigEnabled && (
            <Body>
              <span
                role="tooltip"
                data-microtip-position="top"
                data-microtip-size="medium"
                aria-label="Caso você deseje alterar seu tempo de cama, entre em contato com o suporte."
              >
                Seu tempo de cama é {toHour(new_time_in_bed)} &nbsp; <FaIcon.Question />
              </span>
            </Body>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={7} lg={7}>
          <Form onSubmit={handleSubmit}>
            {bedTimeConfigEnabled && (
              <Row mb>
                <Col xs={12}>
                  <Form.Field
                    name="bedTime"
                    onValueChange={handleBedTime}
                    initialValue={value.timeInBed}
                    key={value.hourToSleepKey}
                    validators={[
                      Validators.TimeInBed(`O seu tempo de cama deve estar entre 5:00 e ${toHour(new_time_in_bed)}`, new_time_in_bed),
                      Validators.Required('Campo obrigatório.'),
                      Validators.TimeRegex('A hora digitada é inválida.'),
                    ]}
                  >
                    <InputLabel
                      role="tooltip"
                      data-microtip-position="top"
                      data-microtip-size="medium"
                      aria-label="O tempo de sono recomendado para adultos pelas sociedades de sono é de 7h a 9h"
                    >
                      Tempo de cama &nbsp;{' '}
                      <span role="img" aria-label="Anoitecer">
                        🛏️
                      </span>
                      &nbsp; <FaIcon.Question />
                    </InputLabel>
                    <DurationField />
                  </Form.Field>
                  <Separator />
                </Col>
              </Row>
            )}
            <Row mb>
              <Col xs={5}>
                <Form.Field
                  name="hourToSleep"
                  onValueChange={handlehourToSleep}
                  initialValue={value.hourToSleep}
                  key={value.hourToSleepKey}
                  validators={[Validators.Required('Campo obrigatório.'), Validators.TimeRegex('A hora digitada é inválida.')]}
                >
                  <ForcedFade show={true}>
                    <InputLabel>
                      Horário de dormir &nbsp;{' '}
                      <span role="img" aria-label="Anoitecer">
                        🌃
                      </span>
                    </InputLabel>
                    <TimeField mobile={mobile} />
                  </ForcedFade>
                </Form.Field>
                <Separator />
              </Col>
              <Hbox.Separator />
              <Hbox.Separator />
              <Col xs={5}>
                <Form.Field
                  name="desiredWakeUp"
                  onValueChange={handleWakeUp}
                  initialValue={value.wakeUp}
                  key={value.wakeUpKey}
                  validators={[Validators.Required('Campo obrigatório.'), Validators.TimeRegex('A hora digitada é inválida.')]}
                >
                  <ForcedFade show={true}>
                    <InputLabel>
                      Horário de acordar &nbsp;{'  '}
                      <span role="img" aria-label="Amanhecer">
                        🌅
                      </span>
                    </InputLabel>
                    <TimeField mobile={mobile} />
                  </ForcedFade>
                </Form.Field>
                <Separator />
              </Col>
            </Row>

            <Row mb center={'xs'}>
              <Col xs={12} sm={12} md={4} lg={4}>
                <GatsbyButton type="submit" kind="primary" loading={props.submitLoading} expanded>
                  Salvar horários
                </GatsbyButton>
              </Col>
            </Row>
            <Separator />
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default SleepRestrictionConfig;

export const query = graphql`
  query SleepRestrictionConfig {
    site {
      ...SiteUrl
    }
  }
`;

function useGetSleepRestriction(): [Sleep_Restriction, boolean, boolean] {
  const { data, loading, error } = useQueryCustom(SleepRestrictionConfigQuery);
  const sleepRestriction: Sleep_Restriction = data?.sleep_restriction?.[0];

  return [sleepRestriction, loading, !!error];
}

function useMutateSleepRestriction() {
  const handleSuccess = React.useCallback((data: Mutation_Root) => {
    try {
      const { hour_to_sleep, desired_wake_up } = data?.update_sleep_restriction?.returning[0];
      flashDispatcherService.dispatchMessage(
        `Sucesso! Dormir ${parseHour(hour_to_sleep)} e acordar ${parseHour(desired_wake_up)} 😉`,
        'success'
      );
    } catch (error) {
      flashDispatcherService.dispatchMessage(`Ocorreu um erro, tente novamente.`, 'alert');
      logError(error);
    }
  }, []);
  return useMutationCustom(SleepRestrictionConfigMutation, { onCompleted: handleSuccess });
}

export const hoursToAllowEditingBedTime = 9;
export function canEditBedTime(newTimeInBed: number, efficiency: number): boolean {
  if (newTimeInBed >= hoursToAllowEditingBedTime * 60 && efficiency >= 85) return true;
  return false;
}
