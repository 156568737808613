import { Hbox } from '@web/atomic';
import { TextFieldMasked } from '@web/atomic/legacy/atm.text-field/text-field-masked.component';
import React, { useCallback } from 'react';
import { HelpTextStyled } from '@web/atomic/atm.help-text/help-text.component.style';

export interface TimeFieldProps {
  mobile: boolean;
  placeholder?: string;
  period?: string;
  disabled?: boolean;
}

export const TimeField: React.FunctionComponent<TimeFieldProps> = (props) => {
  return (
    <Hbox>
      <Hbox.Item wrap>
        <DefaultTimeField {...props} />
      </Hbox.Item>
      {props.period && <Hbox.Separator />}
      {props.period && (
        <Hbox.Item wrap vAlign="flex-end">
          <HelpTextStyled>{props.period}</HelpTextStyled>
        </Hbox.Item>
      )}
    </Hbox>
  );
};

const DefaultTimeField: React.FunctionComponent<TimeFieldProps> = (props) => {
  const handleFocus = useCallback(
    (event) => {
      if (!props.mobile) {
        const target = event.target;
        /**
         * set timeout makes sure the field is filled (in case you use initialValueOnFocus)
         */
        setTimeout(() => target.select(), 10);
      }
    },
    [props.mobile]
  );
  return (
    <TextFieldMasked
      disabled={props.disabled}
      onFocus={handleFocus}
      kind="datetime"
      options={{ format: 'HH:mm' }}
      placeholder={props.placeholder}
      type={props.mobile ? 'time' : 'text'}
      pattern="[0-9]{2}:[0-9]{2}"
      maxLength={8}
    />
  );
};
