import {
  SleepRestrictionConfigMutationMutation,
  SleepRestrictionConfigMutationMutationVariables,
} from '@global/utils/remote-graphql-types';
import { TypedDocumentNode } from '@web/data/apollo-client';
import gql from 'graphql-tag';

export const SleepRestrictionConfigMutation: TypedDocumentNode<
  SleepRestrictionConfigMutationMutation,
  SleepRestrictionConfigMutationMutationVariables
> = gql`
  mutation SleepRestrictionConfigMutation($startDate: date!, $id: String!, $object: sleep_restriction_set_input!) {
    update_sleep_restriction(where: { user_id: { _eq: $id }, start_date: { _eq: $startDate } }, _set: $object) {
      returning {
        hour_to_sleep
        desired_wake_up
      }
    }
  }
`;
