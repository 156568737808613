import { GrayColor, InputValueCss } from '@web/atomic';
import styled from 'styled-components';

export const HelpTextStyled = styled.p`
  ${InputValueCss}
  color: ${(props) => (props.theme.name === 'dark' ? GrayColor.GrayXLight : GrayColor.Gray)};
  :before {
    content: '(';
  }
  :after {
    content: ')';
  }
`;
