import { twoDigit } from '../number/two-digit';

/**
 *
 * @param time ex: `11:00:00+00` -> `11:00`
 */
export const parseHour = (time: string): string => {
  return time?.match(/\d\d:\d\d/)?.[0];
};

export const generateHHMM = (hour: number, minute: number) => {
  return `${twoDigit(hour)}:${twoDigit(minute)}`;
};
