import { MutationHookOptions, MutationTuple, TypedDocumentNode, useMutation } from '@apollo/client';
import { Mutation_Root } from '@global/utils/remote-graphql-types';
import flashDispatcherService from '@web/atomic/legacy/obj.flash-wrapper/flash-dispatcher.service';
import { NativeHelper } from '@web/data/native.helper';
import { logError } from '@web/utils/error';
import { JsonObject } from 'type-fest';
import { AuthClientErrors } from './messenger-auth-client.model';

export const useMutationCustom = <R = Mutation_Root, V = JsonObject>(
  query: TypedDocumentNode<R, V>,
  options?: MutationHookOptions<R, V>
): MutationTuple<R, V> => {
  const customOptions: MutationHookOptions<R, V> = {
    onError: (error) => {
      NativeHelper.postMessage({ type: 'error', data: { error } });
      console.error('ERROR: useMutationCustom -> error', error);
      let message = 'Erro ao enviar os dados. Por favor, tente novamente em 1 minuto.';
      if (error?.message?.includes(AuthClientErrors.NoInternetError)) {
        message = 'Parece que você está sem internet. Verifique sua conexão e tente novamente.';
      }
      flashDispatcherService.dispatchMessage(message, 'alert');
      logError(error);
    },
  };
  return useMutation<R, V>(query, Object.assign(customOptions, options));
};
