import gql from 'graphql-tag';

export const SleepRestrictionConfigQuery = gql`
  query SleepRestrictionConfigQuery {
    sleep_restriction(order_by: { start_date: desc }, limit: 1) {
      user_id
      start_date
      desired_wake_up
      hour_to_sleep
      new_time_in_bed
      comment
      before_eficiency
    }
  }
`;
